import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Spinner } from "react-bootstrap";
import Banniere from "../../components/banniere/banniere";
import Footer from "../../components/footer/footer";
import Services from "../../components/section/services/services";
import ZoneIntervention from "../../components/section/zone-intervention/zone-intervention";
import Controle from "../../components/section/controle/controle";
import Marques from "../../components/section/marques/marques";
import FormContact from "../form/form-contact";
import Alert from "../../components/alert/alert";
import useEmailSenderHook from "../../hook/useEmailSender";
import { Helmet } from "react-helmet";

import "../../components/modal/modal.css";

const LandingPage = () => {
  const { t } = useTranslation();
const { alert, loading, envoye, sendEmail, closeAlert } = useEmailSenderHook();
  return (
    <>
      <Helmet>
        <title>{t("meta.landingPage.title")}</title>
        <meta name="title" content={t("meta.landingPage.titleMeta")} />
        <meta name="description" content={t("meta.landingPage.description")} />
        <link rel="canonical" href="https://www.sphayon.fr" />
      </Helmet>

      <header>
        <Banniere />
      </header>

      <main>         
        <Services />
        <ZoneIntervention />
        <Controle />
        <Marques />
        <FormContact validation={sendEmail} envoye={envoye}/>
        <Container>
          {loading && (
            <Spinner
              className="text-center mt-3 mb-3 p-4 mx-auto d-flex"
              animation="border"
              variant="danger"
            />
          )}
          {!loading && alert.text && (
            <Alert
              show={alert.show}
              variant={alert.variant}
              close={closeAlert}
            >
              {alert.text}
            </Alert>
          )}
        </Container>
      </main>

      <footer>
        <Footer />
      </footer>
    </>
  );
};
export default LandingPage;
