import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import "./modal.css";

const Modal = ({ isShowing, overlayClosed, hide, title, type, children }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className="modal-overlay" onClick={overlayClosed ? hide : null}>
            <div className="modal-wrapper">
              <div className={`modal ${type}`}>
              {title ? <div className="modal-header">
                  <h4>{title}</h4>
                  <button
                    type="button"
                    className="modal-close-button"
                    onClick={hide}
                  >
                    <span>&times;</span>
                  </button>
                </div> :  <button
                    type="button"
                    className="modal-close-button right"
                    onClick={hide}
                  >
                    <span>&times;</span>
                  </button>}
                
                <div className="modal-body">{children}</div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;



Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  type: PropTypes.string,
  overlayClosed: PropTypes.bool.isRequired,
  hide: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Modal;
