import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Container, Jumbotron, Image } from "react-bootstrap";
import "./banniere.css";
import img24 from "../../asset/images/24_24.png";

const component = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="banierre" id="header">
        <div className="overlay">
          <Row>
            <Col md={{ span: 8, offset: 2 }} className="intro-text">
              <h1>{t("banniere.title")}</h1>
              <p>{t("banniere.subTitle")}</p>
            </Col>
          </Row>
        </div>
      </div>
      <Container>
        <Jumbotron className="jumbotron-contact">
          <Row className="text-center">
            <Col xs={12} md={3} className="mt-3">
              <Image src={img24}></Image>
            </Col>
            <Col xs={(12, { order: "last" })} md={6} className="mt-3">
              <h2>{t("banniere.acroche")}</h2>
            </Col>
            <Col xs={12} md={(3, { order: "last" })} className="mt-3">
              <a href="tel:0611764724">
                <h3>{t("phone")}</h3>
              </a>
              <Button
                className="btn btn-perso-primary btn-perso-lg"
                href="#formContact"
              >
                Contactez-nous
              </Button>
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    </>
  );
};
export default component;
