import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Container, Image } from "react-bootstrap";
import "../section.css";
import ToutMarque from "../../../asset/images/tout_les_marques.jpg";

const Marques = () => {
  const { t } = useTranslation("section");
  return (
    <section id="marques" className="text-center">
      <Container>
        <h2 className="titre">{t("marque.title")}</h2>
        <h4>{t("marque.subTitle")}</h4>
        <Row className="mt-5 mx-auto d-flex">
          <Image
            src={ToutMarque}
            fluid
            className="mx-auto"
            alt={t("marque.altMarque")}
          />
        </Row>
      </Container>
    </section>
  );
};
export default Marques;
