import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Row, Col, Container, Card } from "react-bootstrap";
import Map from "../../../containers/map/map";
import "../section.css";

const ZoneIntervention = () => {
  const { t } = useTranslation("section");
  return (
    <section id="intervention">
      <Container>
        <h2 className="titre text-center">{t("zoneIntervention.title")}</h2>
        <Row>
          <Col xs={12} md={6}>
            <Map />
          </Col>
          <Col xs={12} md={6} className="align-self-center">
            <Card border="warning" className="text-justify mt-2">
              <Card.Body>
                <p>
                  <Trans i18nKey="zoneIntervention.departement">
                    SP Hayon intervient dans les départements de la
                    Haute-Garonne <strong>(31)</strong> et du Tarn{" "}
                    <strong>(81)</strong>.
                  </Trans>
                </p>
                <p>{t("zoneIntervention.argument")}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default ZoneIntervention;
