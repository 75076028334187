import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./footer.css";

const footer = () => {
  const { t } = useTranslation();
  return (
    <div id="footer" className="p-5">
      <Container>
        <Row className="text-center text-white">
          <Col>{t("footer.copyright")}</Col>
        </Row>
        <Row className="text-center text-white">
          <Col>
            <NavLink to="/mention-legal" className="nav-link">
              {t("footer.mentionsLegal")}
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default footer;
